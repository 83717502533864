import { all, put, select, takeLatest, call } from "redux-saga/effects";

import {
  selectAuthToken,
  selectEmail,
  selectOrganisationId,
} from "../selectors/login.selector";
import * as API from "../utils/api";
import axios from "axios";
import {
  getNotes,
  getNotifications,
  markNoteAsReadOrUnread,
  markNotificationAsReadOrUnread,
  setNotes,
  setNotifications,
  triggerMarkNoteAsReadOrUnread,
  triggerMarkNotificationAsReadOrUnread,
} from "../reducers/notification.reducer";

function* handleGetNotesWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);
    const organisationId = yield select(selectOrganisationId);
    const requestURL = API.NOTIFICATIONS.GET_NOTES.replace(
      "<ORGANISATION_ID>",
      organisationId
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    const response = yield axios.get(requestURL, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setNotes(response.data.results.notes));
      }
    }
  } catch (error) {
    console.error("Failed to get notifications:", error);
  }
}
function* handleMarkNoteAsReadOrUnreadWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);
    const organisationId = yield select(selectOrganisationId);
    const requestURL = API.NOTIFICATIONS.MARK_NOTE_AS_READ.replace(
      "<ORGANISATION_ID>",
      organisationId
    )
      .replace("<NOTE_ID>", action.payload.noteId)
      .replace("<IS_READ>", action.payload.isRead);

    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    const response = yield axios.put(requestURL, {}, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(markNoteAsReadOrUnread(response.data.results.notes));
      }
    }
  } catch (error) {
    console.error("Failed to mark notification as read:", error);
  }
}
function* handleGetNotificationsWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);
    const organisationId = yield select(selectOrganisationId);
    const requestURL = API.NOTIFICATIONS.GET_NOTIFICATIONS.replace(
      "<ORGANISATION_ID>",
      organisationId
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    const response = yield axios.get(requestURL, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setNotifications(response.data.results.notifications));
      }
    }
  } catch (error) {
    console.error("Failed to get notifications:", error);
  }
}
function* handleMarkNotificationAsReadOrUnreadWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);
    const organisationId = yield select(selectOrganisationId);

    const requestURL = API.NOTIFICATIONS.MARK_NOTIFICATION_AS_READ.replace(
      "<ORGANISATION_ID>",
      organisationId
    )
      .replace("<NOTIFICATION_ID>", action.payload.notificationId)
      .replace("<IS_READ>", action.payload.isRead);

    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    const response = yield axios.put(requestURL, {}, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(
          markNotificationAsReadOrUnread(response.data.results.notifications)
        );
      }
    }
  } catch (error) {
    console.error("Failed to mark notification as read:", error);
  }
}

function* handleGetNotesSaga(action) {
  yield takeLatest(getNotes.type, handleGetNotesWorker);
}

function* handleMarkNoteAsReadOrUnreadSaga(action) {
  yield takeLatest(
    triggerMarkNoteAsReadOrUnread.type,
    handleMarkNoteAsReadOrUnreadWorker
  );
}
function* handleGetNotificationsSaga(action) {
  yield takeLatest(getNotifications.type, handleGetNotificationsWorker);
}

function* handleMarkNotificationAsReadOrUnreadSaga(action) {
  yield takeLatest(
    triggerMarkNotificationAsReadOrUnread.type,
    handleMarkNotificationAsReadOrUnreadWorker
  );
}

export default function* rootSaga() {
  yield all([
    handleGetNotesSaga(),
    handleMarkNoteAsReadOrUnreadSaga(),
    handleGetNotificationsSaga(),
    handleMarkNotificationAsReadOrUnreadSaga(),
  ]);
}
