import React, { useEffect, useState } from "react";
import { Grid, Box, Modal } from "@mui/material";

import NotificationTabs from "../components/Notifications/notificationTabs.component";
import { selectNotificationTabs } from "../selectors/notification.selector";
import { useDispatch, useSelector } from "react-redux";

import { getNotes, getNotifications } from "../reducers/notification.reducer";
import NotificationsComponent from "../components/Notifications/notifications.component";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  height: "95vh",
  bgcolor: "background.paper",
  p: 4,
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
};
const NotificationsPage = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const selectedTab = useSelector(selectNotificationTabs);
  useEffect(() => {
    dispatch(getNotes());
    dispatch(getNotifications());
  }, []);
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="notification-modal-title"
    >
      <Box sx={style}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={12} sx={{ mt: 1, mb: 2 }}>
            <NotificationTabs />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              flexGrow: 1,
              overflow: "hidden",
            }}
          >
            <NotificationsComponent
              type={selectedTab}
              open={open}
              onClose={onClose}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default NotificationsPage;
