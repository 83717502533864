import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import {
  selectNotifications,
  selectSupplierNotes,
} from "../../selectors/notification.selector";
import {
  getNotes,
  getNotifications,
  triggerMarkNoteAsReadOrUnread,
  triggerMarkNotificationAsReadOrUnread,
} from "../../reducers/notification.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import DraftsIcon from "@mui/icons-material/Drafts";
import { getAuditById } from "../../reducers/audit.reducer";

const NotificationsComponent = ({ open, onClose, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedNotifications = useSelector(selectNotifications);
  const selectedNotes = useSelector(selectSupplierNotes);

  const [notifications, setNotifications] = useState(selectedNotes);
  const handleCardClick = (notification) => {
    if (
      notification.supplierId &&
      !notification.factoryId &&
      !notification.auditId
    ) {
      navigate(`/suppliers/${notification.supplierId}`);
    } else if (
      notification.supplierId &&
      notification.factoryId &&
      !notification.auditId
    ) {
      navigate(
        `/suppliers/${notification.supplierId}/factory/${notification.factoryId}`
      );
    } else if (
      notification.supplierId &&
      notification.factoryId &&
      notification.auditId
    ) {
      dispatch(
        getAuditById({
          supplierId: notification.supplierId,
          factoryId: notification.factoryId,
          auditId: notification.auditId,
          origin: "notification",
        })
      );
    }
  };

  const handleMarkAsReadOrUnread = (e, noteId, isRead) => {
    e.stopPropagation();
    if (type === "notes") {
      dispatch(triggerMarkNoteAsReadOrUnread({ noteId, isRead: !isRead }));
    } else {
      dispatch(
        triggerMarkNotificationAsReadOrUnread({
          notificationId: noteId,
          isRead: !isRead,
        })
      );
    }
  };
  // first load
  useEffect(() => {
    if (type === "notes") {
      dispatch(getNotes());
      setNotifications(selectedNotes);
    } else {
      dispatch(getNotifications());
      setNotifications(selectedNotifications);
    }
  }, [type]);

  // update when notifications or notes change
  useEffect(() => {
    if (type === "notes") {
      setNotifications(selectedNotes);
    } else {
      setNotifications(selectedNotifications);
    }
  }, [selectedNotes, selectedNotifications]);
  return (
    <Box
      sx={{
        height: "calc(95vh - 120px)",
        overflowY: "auto",
        pr: 2,
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#888",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }}
    >
      {notifications?.map((notification) => (
        <Card
          key={notification._id}
          sx={{
            mb: 2,
            boxShadow:
              "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
            border: "1px solid rgba(234, 236, 240, 1)",
            borderRadius: "12px",
            cursor: "pointer",
            "&:hover": {
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            },
          }}
          onClick={() => handleCardClick(notification)}
        >
          <CardContent>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="start"
            >
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{
                  mb: 1,

                  backgroundColor: "#F2F4F7",
                  p: 1,
                  borderRadius: "12px",
                  flex: 1,
                }}
              >
                <strong>Supplier</strong>: {notification.supplierName}
                {notification.factoryName && (
                  <>
                    {" "}
                    | <strong>Site</strong>: {notification.factoryName}
                  </>
                )}
                {notification.auditName &&
                  notification.auditName !== "null" && (
                    <>
                      {" "}
                      | <strong>Audit</strong>: {notification.auditName}
                      <br />
                    </>
                  )}
              </Typography>
              <IconButton
                onClick={(e) =>
                  handleMarkAsReadOrUnread(
                    e,
                    notification._id,
                    notification?.isRead || false
                  )
                }
                sx={{ ml: 1 }}
              >
                {notification.isRead ? (
                  <Tooltip title="Mark as unread">
                    <DraftsIcon sx={{ color: "#1976d2" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Mark as read">
                    <MarkEmailUnreadIcon />
                  </Tooltip>
                )}
              </IconButton>
            </Stack>
            <Typography
              sx={{ mt: 1, pl: 1, fontWeight: 600, fontSize: "16px" }}
            >
              {notification.subject}
            </Typography>
            <Typography
              sx={{ mt: 1, pl: 1, fontWeight: 400, fontSize: "14px" }}
            >
              {notification.text}
            </Typography>

            <Typography
              sx={{ mt: 1, fontSize: "8px", textAlign: "right" }}
              color="text.secondary"
            >
              {notification?.authorName}
              <br />
              {moment(notification.createdAt).format("HH:mm A, MMM. DD, YYYY")}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default NotificationsComponent;
