import React, { useState } from "react";
import { useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Divider,
  Button,
  Skeleton,
  Card,
  Switch,
  Drawer,
  Modal,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import TopBreadcrumbs from "../components/topBreadcrumbs.component";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import {
  selectAuditNameFromId,
  selectAuditPageTab,
  selectAuditXlsxFile,
  selectSelectedAuditIssueId,
  selectAuditData,
} from "../selectors/audit.selector";
import AuditInformation from "../components/AuditDetails/auditInformation.component";
import ListOfAuditDocuments from "../components/AuditDetails/listOfAuditDocuments.component";
import AuditTabs from "../components/AuditDetails/auditTabs.component";
import AuditIframe from "../components/AuditDetails/auditIframe.component";
import {
  selectAuditPageFactoryDataTitle,
  selectFactoryData,
} from "../selectors/factory.selector";
import EditToggleSwitch from "../components/editToggleSwitch.component";
import AuditsTable from "../components/AuditDetails/auditsTable.component";
import AuditCapProgressStepper from "../components/AuditDetails/auditCapProgressStepper.component";
import FacilityIssuesCard from "../components/FacilityDetails/facilityIssuesCard.component";
import AuditIssuesTable from "../components/AuditDetails/auditIssuesTable.component";
import AuditReportDetails from "./auditReportDetails.page";
import AuditDocumentStorage from "../components/AuditDetails/auditDocumentStorage.component";
import { toggleEditModeForAudit } from "../reducers/misc.reducer";
import { PageStyles } from "../styles";
import {
  uploadAuditReport,
  getAuditById,
  regenerateCap,
  setAuditData,
  setAuditIssueCAPData,
  setSelectedAuditIssueId,
} from "../reducers/audit.reducer";
import { selectEmail, selectOrganisationId } from "../selectors/login.selector";
import { checkInternalEmail } from "../utils/lib";
import { selectSaveStatus } from "../selectors/misc.selector";
import { selectSupplierData } from "../selectors/supplier.selector";
import { getSupplierById } from "../reducers/supplier.reducer";
import AuditDocumentDrop from "../components/AuditDetails/auditDocumentDrop.component";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const AuditDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  // Drawer state management
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const userEmail = useSelector(selectEmail);
  const isInternalUser = checkInternalEmail(userEmail);
  const isSomethingUnsaved = useSelector(selectSaveStatus);

  // Update the drawer based on the selectedAuditIssueId
  const selectedAuditIssueId = useSelector(selectSelectedAuditIssueId);
  React.useEffect(() => {
    // Ensure the drawer opens only if there is a valid, non-null `selectedAuditIssueId`
    if (selectedAuditIssueId) {
      setIsDrawerOpen(true);
    } else {
      setIsDrawerOpen(false);
    }
  }, [selectedAuditIssueId]);

  // Handle close drawer
  const handleDrawerClose = () => {
    if (isSomethingUnsaved === "updated") {
      alert(
        "You have unsaved changes on the page. Please save or discard them before closing."
      );
    } else {
      dispatch(setSelectedAuditIssueId(null));
      dispatch(toggleEditModeForAudit(false));
      setIsDrawerOpen(false);
    }
  };

  const auditName = useSelector((state) =>
    selectAuditNameFromId(state, params.auditId)
  );
  const selectedAuditData = useSelector(selectAuditData);
  const selectedFactoryData = useSelector(selectFactoryData);
  const selectedTab = useSelector(selectAuditPageTab);
  // Define organisationId, auditId, supplierId, and factoryId
  const organisationId = useSelector(selectOrganisationId);
  const auditId = params.auditId;
  const supplierId = params.supplierId;
  const factoryId = params.factoryId;
  const supplierData = useSelector(selectSupplierData);

  useEffect(() => {
    if (!supplierData || supplierData?.id !== params.supplierId) {
      dispatch(getSupplierById(params.supplierId));
    }
  }, [params.supplierId, dispatch]);

  React.useEffect(() => {
    if (selectedAuditData == null) {
      dispatch(
        getAuditById({
          supplierId: supplierId,
          factoryId: factoryId,
          auditId: auditId,
        })
      );
    }
  }, []);

  // Handle file selection
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      dispatch(
        uploadAuditReport(
          { organisationId, auditId, supplierId, factoryId, file },
          { file }
        )
      );
    }
  };

  // Handle regenerate export
  const handleRegenerateExport = () => {
    dispatch(regenerateCap({ organisationId, auditId, supplierId, factoryId }));
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={PageStyles.containerBox}>
      <LeftSideNavbar />
      <Box sx={PageStyles.containerBoxExceptNavbar}>
        <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
          <TopBreadcrumbs />
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography gutterBottom sx={PageStyles.name}>
                {selectedAuditData?.metadata?.facilityData?.auditing_standard} (
                {moment(
                  selectedAuditData?.metadata?.facilityData?.audit_end
                ).format("MMM. DD, YYYY")}
                )
              </Typography>
              {selectedAuditData?.state &&
                selectedAuditData.state === "test" && (
                  <Typography color="red" sx={{ fontSize: "10px", mb: 1 }}>
                    Internal - Not Approved
                  </Typography>
                )}
            </Grid>
            <Grid item>
              <EditToggleSwitch />
            </Grid>
          </Grid>
        </Box>
        <Divider />

        <Grid container spacing={2} sx={PageStyles.secondSectionGrid}>
          <Grid item xs={12}>
            <Card sx={PageStyles.auditContainerCard}>
            {selectedAuditData?.state === "deleted" ? (
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        // backgroundColor: "#f0f0f0",
                        py: "64px",
                        px: "32px",
                        borderRadius: "4px",
                        marginBottom: "16px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                        Platform Message
                      </Typography>
                      <Typography sx={{ color: "#666", fontSize: "20px", maxWidth: "80%" }}>
                        You have successfully delete this audit from the platform. AuditSense
                        AI will temporarily store the deleted data for 30 days
                        before permanently deleting it. If you wish to recover
                        the data or to permanently delete the data immediately,
                        please contact the Elm AI team.
                      </Typography>
                    </Box>
                  </Grid>
                  </Grid>
              ) : (
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                  <AuditInformation />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5} sx={{ p: 2 }}>
                  <AuditIssuesTable />
                </Grid>
                <Grid item xs={12}>
                  <AuditCapProgressStepper auditData={selectedAuditData} />
                </Grid>
                <Grid item xs={12} sx={{ mt: 4, mb: 2 }}>
                  <AuditTabs />
                </Grid>
                <Grid item xs={12}>
                  {selectedTab === "issues" ? (
                    <AuditsTable />
                  ) : selectedTab === "document" ? (
                    <>
                      {/* <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(234, 236, 240, 0.5)",
                          padding: "8px 16px",
                          borderRadius: "8px",
                          mb: 2,
                          flexDirection: "column",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() =>
                            document.getElementById("file-upload-input").click()
                          }
                          sx={{
                            mt: 1,
                            color: "#3538CD",
                            fontSize: "14px",
                            fontWeight: 600,
                            padding: "8px 12px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "8px",
                            border: "1px solid #A4BCFD",
                            background: "#FFF",
                            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                            height: "fit-content",
                            textTransform: "none",
                          }}
                          // disabled={!isInternalUser}
                        >
                          Upload
                        </Button>
                        <input
                          type="file"
                          id="file-upload-input"
                          style={{ display: "none" }}
                          onChange={handleFileSelect}
                        />
                      </Box> */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(234, 236, 240, 0.5)",
                          padding: "8px 16px",
                          borderRadius: "8px",
                          mb: 2,
                          flexDirection: "column",
                        }}
                      >
                        <Button
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            color: "#344054",
                            borderRadius: "8px",
                            padding: "10px 14px",
                            border: "1px solid var(--Gray-3000, #D0D5DD)",
                            background: "var(--Base-White, #FFF)",
                            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                            justifyContent: "center",
                            alignItems: "center",
                            textTransform: "none",
                          }}
                          onClick={handleOpen}
                          startIcon={<UploadFileIcon />}
                        >
                          Upload Document
                        </Button>
                      </Box>
                      <ListOfAuditDocuments includeType="document" />
                      <Modal open={open} onClose={handleClose}>
                        <Card
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 1000,
                            p: 4,
                            boxShadow:
                              "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
                            border: "1px solid rgba(234, 236, 240, 1)",
                            borderRadius: "12px",
                            py: 2,
                          }}
                        >
                          <AuditDocumentDrop
                            organisationId={organisationId}
                            supplierId={params.supplierId}
                            factoryId={params.factoryId}
                            auditId={auditId}
                            onClose={handleClose}
                            mode="something"
                          />
                        </Card>
                      </Modal>
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(234, 236, 240, 0.5)",
                          padding: "8px 16px",
                          borderRadius: "8px",
                          mb: 2,
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.6)",
                            fontWeight: 500,
                            fontStyle: "italic",
                            textAlign: "center",
                          }}
                        >
                          If the audit report is edited, click the button to
                          regenerate for the latest data.
                        </Typography>
                        <Button
                          variant="outlined"
                          onClick={handleRegenerateExport}
                          sx={{
                            mt: 1,
                            color: "#3538CD",
                            fontSize: "14px",
                            fontWeight: 600,
                            padding: "8px 12px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "8px",
                            border: "1px solid #A4BCFD",
                            background: "#FFF",
                            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                            height: "fit-content",
                            textTransform: "none",
                          }}
                        >
                          Regenerate Export
                        </Button>
                      </Box>
                      <ListOfAuditDocuments includeType="export" />
                    </>
                  )}
                </Grid>
              </Grid>
            )}
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            maxHeight: "98%",
            maxWidth: "98%",
            display: "flex",
            justifySelf: "center",
          },
        }}
      >
        <AuditReportDetails handleDrawerClose={handleDrawerClose} />
      </Drawer>
    </Box>
  );
};

export default AuditDetails;
