// UpdateModal.jsx
import React from "react";
import { Box, Modal, Typography, Divider, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ComponentStyles } from "../styles";
const updateContent = [
  {
    updateDate: "Sept 27, 2024",
    changes: [
      "On the insight page, clicking the factory name in the list of audit module now navigates to the corresponding audit details page",
    ],
  },
  {
    updateDate: "Sept 26, 2024",
    changes: [
      "Add facility status feature: 1) Change factory and supplier status in edit, 2) show 'inactive' tag on header for inactive factories and suppliers, 3) display 'inactive' in site and supplier table if inactive, 4) add 'status' column on insight page",
      "Display all addresses on the site's profile",
      "Uploaded the supporting document on the audit details page",
      "Fix bugs and improve UI"
    ],
  },
  {
    updateDate: "Sept 22, 2024",
    changes: [
      "Close CAP based on the CAP tracker",
      "Add two filters on the data insight page",
      "Populate the relevant data on the supplier data table and site data table on the insight page"
    ],
  },
  {
    updateDate: "Sept 16, 2024",
    changes: [
      "Completed the 1st round of data transfer with AI analysis for each report",
      "Enable report upload, supporting documents upload, and CAP excel download on audit review page"
    ],
  },
  {
    updateDate: "Sept 10, 2024",
    changes: [
      "Add function to delete individual finding on audit details page when in edit mode",
      "Minor bug fixes and UI improvement",
    ],
  },
  {
    updateDate: "Sept 4, 2024",
    changes: [
      "Connect data with Due Action Notification on site profile",
      "Include more data in supplier and site data table",
      "Add the original audit score when hovering the icon on audit score on the audit details page if the original report has a score, show",
    ],
  },
  {
    updateDate: "August 28, 2024",
    changes: [
      "Insight page for supply chain level compliance insight",
      "Risk Map for prioritizing supplier based on audit score and spend",
      "Supplier Data Table for supplier data management and convenient export",
      "Site Data Table for site data management and convenient export"
    ],
  },
  {
    updateDate: "August 23, 2024",
    changes: [
      "Update couple supplier and site data",
      "Re-organize the information in \"More Information\" on site and supplier pages",
      "Separate the site tier map into separate pop up"
    ],
  },
  {
    updateDate: "August 20, 2024",
    changes: [
      "Update all supplier ID with random digits.",
      "Make all profile data editable.",
      "Add the audit firm in the audit list card.",
      "Add factory tier map in the supplier profile page",
      "Minor Bug fixes, and UI improvements.",
    ],
  },
  {
    updateDate: "August 14, 2024",
    changes: [
      "Update all the supplier and site data.",
      "Upgrade the supplier list and site list for more capabilitieis.",
    ],
  },
  {
    updateDate: "August 5, 2024",
    changes: [
      "Add the audit report to the link navigation on the top.",
      "Add the private label tag in the site list on the supplier page.",
      "Implement the updated design for the findings summary card in the site status page.",
      "Implement the updated design for the findings summary card with Severity breakdwon in the audit report page.",
      "Show audit score in the audit report page.",
      'Change all “issues" to “findings”.',
      'Change all "audit result" to “audit score”.',
      "Other minor bug fixes and UI improvements.",
    ],
  },
];

const LatestUpdateModal = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "10%",
          left: "50%",
          transform: "translate(-50%, 0)",
          bgcolor: "background.paper",
          boxShadow: 24,
          pb: 4,
          px: 2,
          borderRadius: "12px",
          minWidth: "75%",
          minHeight: "80%",
          maxWidth: "90%",
          maxHeight: "80%",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            display: "flex",
            alignItems: "center",
            mb: 2,
            py: 1.5,
            px: 2,
            bgcolor: "background.paper",
            zIndex: 1,
            boxShadow: "0px 4px 4px -4px rgba(0, 0, 0, 0.2)", // Add this line to create a bottom border effect
          }}
        >
          <IconButton onClick={onClose}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            ml={1}
            sx={{
              color: "text.secondary",
              fontSize: "15px",
              fontWeight: 600,
              lineHeight: "24px",
            }}
            variant="subtitle1"
          >
            Latest Updates
          </Typography>
        </Box>

        <Box sx={{ width: "100%", px: 8.5, pt: 2 }}>
          {updateContent.map((update, index) => (
            <React.Fragment key={index}>
              <Typography mb={2} sx={ComponentStyles.headingsName}>
                <strong>Release Date</strong>
              </Typography>
              <Typography mb={2} sx={ComponentStyles.headingsName}>
                {update.updateDate}
              </Typography>

              <Typography mb={2} sx={ComponentStyles.headingsName}>
                <strong>Update Details</strong>
              </Typography>
              <Typography mb={2} sx={ComponentStyles.headingsName}>
                {update.changes.map((change, idx) => (
                  <li key={idx}>{change}</li>
                ))}
              </Typography>
              {index < updateContent.length - 1 && <Divider sx={{ my: 4 }} />}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default LatestUpdateModal;
