import React from "react";
import { Box, Grid, Typography, Drawer, Divider } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { selectSupplierNameFromId } from "../../selectors/supplier.selector";
import { useSelector } from "react-redux";
import { ComponentStyles, FacilityStyles } from "../../styles";
import FacilityTierMap from "./facilityTierMap.component";

const FacilityInformationDrawer = ({
  factoryData,
  isDrawerOpen,
  toggleDrawer,
}) => {
  const params = useParams();
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, params.supplierId)
  );

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{ width: 600, padding: 5, paddingLeft: 6, paddingRight: 6 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Box>
          <Typography variant="h4" sx={{ mb: 4, mt: 2 }}>
            More Information
          </Typography>

          <Divider sx={{ mb: 4 }} />

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Alias</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                {factoryData?.metadata?.alias || "-"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={ComponentStyles.fieldName}>
                Associated Addresses
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.associatedAddress || "-"}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 3, mb: 3 }} />

          {Array.isArray(factoryData?.metadata?.additionalContacts) &&
            factoryData?.metadata?.additionalContacts
              .filter((contact) => !contact.type || contact.type !== "CAP")
              .map((contact, index) => (
                <>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      Contact Person {index + 1}
                    </Typography>
                    <Grid container spacing={0} justifyContent="space-between">
                      <Grid item xs={6} sx={{ pr: 3 }}>
                        <Typography sx={ComponentStyles.fieldName}>
                          Name
                        </Typography>
                        <Typography
                          sx={{
                            ...ComponentStyles.fieldValue,
                            wordBreak: "break-word",
                          }}
                        >
                          {contact?.contactPersonName || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ pr: 3 }}>
                        <Typography sx={ComponentStyles.fieldName}>
                          Position
                        </Typography>
                        <Typography
                          sx={{
                            ...ComponentStyles.fieldValue,
                            wordBreak: "break-word",
                          }}
                        >
                          {contact?.contactPersonPosition || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ pr: 3 }}>
                        <Typography sx={ComponentStyles.fieldName}>
                          Phone
                        </Typography>
                        <Typography
                          sx={{
                            ...ComponentStyles.fieldValue,
                            wordBreak: "break-word",
                          }}
                        >
                          {contact?.contactPersonPhone || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ pr: 3 }}>
                        <Typography sx={ComponentStyles.fieldName}>
                          Email
                        </Typography>
                        <Typography
                          sx={{
                            ...ComponentStyles.fieldValue,
                            wordBreak: "break-word",
                          }}
                        >
                          {contact?.contactPersonEmail || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider sx={{ mb: 2 }} />
                </>
              ))}

          {Array.isArray(factoryData?.metadata?.additionalContacts) &&
            factoryData?.metadata?.additionalContacts.some(
              (contact) => contact.type === "CAP"
            ) && (
              <>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  CAP Contact
                </Typography>
                {factoryData?.metadata?.additionalContacts
                  .filter((contact) => contact.type === "CAP")
                  .map((contact, index) => (
                    <>
                      <Box sx={{ mb: 2 }}>
                        <Grid
                          container
                          spacing={0}
                          justifyContent="space-between"
                        >
                          <Grid item xs={6} sx={{ pr: 3 }}>
                            <Typography sx={ComponentStyles.fieldName}>
                              Email
                            </Typography>
                            <Typography
                              sx={{
                                ...ComponentStyles.fieldValue,
                                wordBreak: "break-word",
                              }}
                            >
                              {contact?.contactPersonEmail || "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider sx={{ mb: 2 }} />
                    </>
                  ))}
              </>
            )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default FacilityInformationDrawer;
