import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import TwoLineCard from '../twoLineCard.component';
import { FacilityStyles } from '../../styles';

const FacilityEnvironmental = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography sx={FacilityStyles.mostRecentAuditHeading}>
        Emission
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TwoLineCard
            title="GHG Emissions"
            nextAuditDate="-"
          />
        </Grid>
        <Grid item xs={6}>
          <TwoLineCard
            title="Other Metrics"
            nextAuditDate="-"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FacilityEnvironmental; 