import { createSlice } from "@reduxjs/toolkit";
import { logout } from "./login.reducer";
const initialState = {
  loading: false,
  notes: [],
  notifications: [],
  notificationTabs: "notes",
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    loading: (state, action) => {
      state.loading = action.payload;
    },
    getNotes: (state, action) => {},
    setNotes: (state, action) => {
      state.notes = action.payload;
    },
    setNotificationTabs: (state, action) => {
      state.notificationTabs = action.payload;
    },
    triggerMarkNoteAsReadOrUnread: (state, action) => {},
    markNoteAsReadOrUnread: (state, action) => {
      state.notes = state.notes.map((note) => {
        if (note._id.toString() === action.payload._id.toString()) {
          return { ...note, isRead: action.payload.isRead };
        }
        return note;
      });
    },
    getNotifications: (state, action) => {},
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    triggerMarkNotificationAsReadOrUnread: (state, action) => {},
    markNotificationAsReadOrUnread: (state, action) => {
      state.notifications = state.notifications.map((notification) => {
        if (notification._id.toString() === action.payload._id.toString()) {
          return { ...notification, isRead: action.payload.isRead };
        }
        return notification;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const {
  loading,
  getNotes,
  setNotes,
  triggerMarkNoteAsReadOrUnread,
  markNoteAsReadOrUnread,
  setNotificationTabs,
  getNotifications,
  setNotifications,
  triggerMarkNotificationAsReadOrUnread,
  markNotificationAsReadOrUnread,
} = notificationSlice.actions;

export default notificationSlice.reducer;
