import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  Divider,
  CardContent,
  Tooltip,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectFactoryData } from "../../selectors/factory.selector";
import {
  selectAuditCAPStatus,
  selectAuditData,
  selectAuditsWhichAreInProgressOfCapManagement,
} from "../../selectors/audit.selector";
import { getFactoryById } from "../../reducers/factory.reducer";
import { useParams } from "react-router-dom";
import moment from "moment";
import AuditInformationEditModal from "./EditModals/editAuditInformation.component";
import { selectEditModeState } from "../../selectors/misc.selector";
import EditIcon from "@mui/icons-material/Edit";
import { AuditStyles, ComponentStyles } from "../../styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { beginCAPManagement } from "../../reducers/audit.reducer";
import { updateAuditData } from "../../reducers/audit.reducer";

const AuditInformation = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const selectedAuditData = useSelector(selectAuditData);
  const selectedFactoryData = useSelector(selectFactoryData);
  const [factoryData, setFactoryData] = useState(selectedFactoryData);
  const [isEditingInternal, setIsEditingInternal] = useState(false);

  const editMode = useSelector(selectEditModeState);
  const auditsInProgress = useSelector(
    selectAuditsWhichAreInProgressOfCapManagement
  );

  const auditCAPStatus = useSelector(selectAuditCAPStatus);
  useEffect(() => {
    if (
      selectedAuditData &&
      ((selectedFactoryData?._id &&
        selectedAuditData?.factoryId !== selectedFactoryData?._id) ||
        !selectedFactoryData)
    ) {
      dispatch(
        getFactoryById({
          supplierId: params.supplierId,
          factoryId: selectedAuditData.factoryId,
        })
      );
    }
    setFactoryData(selectedFactoryData);
  }, [selectedFactoryData, selectedAuditData]);

  const toggleEditInternal = () => {
    setIsEditingInternal(!isEditingInternal);
  };

  const handleBeginCAPManagement = () => {
    const changes = {
      capStartDateFacilityData: new Date().toISOString(),
    };

    dispatch(
      beginCAPManagement({
        supplierId: params.supplierId,
        factoryId: params.factoryId,
        auditId: params.auditId,
      })
    );

    dispatch(
      updateAuditData({
        organisationId: selectedAuditData?.organisationId,
        supplierId: selectedAuditData?.supplierId,
        factoryId: selectedAuditData?.factoryId,
        auditId: selectedAuditData?._id,
        changes,
      })
    );
  };

  return (
    factoryData &&
    (!editMode ? (
      <Box sx={AuditStyles.containerBox}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          sx={AuditStyles.containerGrid}
        >
          <Grid item>
            <Typography
              sx={{
                ...AuditStyles.headingsNameBold700,
                display: "inline-block",
                alignSelf: "center",
              }}
            >
              Audit Information
            </Typography>
          </Grid>
          {editMode && (
            <EditIcon
              fontSize={"2"}
              sx={ComponentStyles.editButton}
              onClick={toggleEditInternal}
            />
          )}
          <Grid item>
            <Card variant="outlined" style={AuditStyles.editModeCard}>
              <CardContent
                style={{
                  padding: "4px",
                  paddingLeft: "32px",
                  paddingRight: selectedAuditData?.metadata?.originalAuditScore
                    ? "18px"
                    : "32px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography style={AuditStyles.headingsNameBold700}>
                  Audit Score
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: "16px 16px" }}
                />
                <Typography style={AuditStyles.bigHeadingFont30}>
                  {selectedAuditData?.risk?.auditScore || "-"}
                </Typography>
                {selectedAuditData?.metadata?.originalAuditScore && (
                  <Tooltip
                    title={`The original score on the audit is ${selectedAuditData?.metadata?.originalAuditScore}`}
                  >
                    <InfoOutlinedIcon
                      sx={{ marginLeft: "8px", color: "#A0A5AD" }}
                    />
                  </Tooltip>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Row 1 */}
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit Start Date</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.audit_start
                ? moment(
                    selectedAuditData.metadata.facilityData.audit_start
                  ).format("MMM. DD, YYYY")
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit End Date</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.audit_end
                ? `${moment(
                    selectedAuditData.metadata.facilityData.audit_end
                  ).format("MMM. DD, YYYY")}`
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Auditor Name</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.auditor_name || "-"}
            </Typography>
          </Grid>
        </Grid>

        {/* Row 2 */}
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>
              Language (Management)
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.language_management ??
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>
              Language (Workers)
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.language_workers ??
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit Funder</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.auditFunder || "-"}
            </Typography>
          </Grid>
        </Grid>

        {/* Row 3 */}
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit Scheme</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.auditing_standard ||
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Auditor Firm</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.auditing_firm || "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>CAP Managed by Audit Firm</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.capManagedByAuditFirm || "-"}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={12}>
            <Typography sx={AuditStyles.fieldName}>Address</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.facility_address ??
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>
              Audit Upload Date
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {moment(selectedAuditData?.createdAt).format("MMM. DD, YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography sx={AuditStyles.fieldName}>
              CAP Start Date (Used for CAP Due Calculation)
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.cap_start_date
                ? moment(
                    selectedAuditData?.metadata?.facilityData?.cap_start_date
                  ).format("MMM. DD, YYYY")
                : `${moment(selectedAuditData?.createdAt).format(
                    "MMM. DD, YYYY"
                  )} (Default as Audit Upload Date)`}
            </Typography>
          </Grid>
        </Grid>

        {/* <Button style={{background: "#2D3282", color: "#FFFFFF", marginLeft:"30px", marginTop: "30px", textTransform: 'none', paddingLeft: "20px", paddingRight: "20px"}}>Begin CAP Management</Button> */}
        {auditCAPStatus === "OPEN" && (
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: "#2D3282",
              borderRadius: "8px",
              border: "1px solid #2D3282",
              padding: "8px 16px",
              fontSize: "16px",
              fontWeight: "600",
              marginLeft: "30px",
              marginTop: "20px",
            }}
            onClick={() => {
              handleBeginCAPManagement();
            }}
          >
            Complete review and publish for CAP Management
          </Button>
        )}
      </Box>
    ) : (
      <AuditInformationEditModal
        open={isEditingInternal}
        onClose={() => setIsEditingInternal(false)}
        factoryData={selectedFactoryData}
        auditData={selectedAuditData}
      />
    ))
  );
};

export default AuditInformation;
