import React from "react";
import {
  Grid,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";

import CustomChipLabel from "../AuditDetails/customChipLabel.component";
import { AuditStyles, ComponentStyles } from "../../styles";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const RecommendationsList = ({ title, items, site }) => (
  <Paper
    elevation={0}
    sx={{
      mb: 2,
      backgroundColor: site ? "#F9FAFB" : "#FFF",
      padding: site ? "4px 8px" : "0px",
      borderRadius: site ? "12px" : "0px",
    }}
  >
    <Typography
      sx={{
        color: "#475467",
        fontSize: "16px",
        fontWeight: 700,
      }}
    >
      {title}
    </Typography>
    <List dense>
      {items.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemText
            primary={
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {item}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  </Paper>
);

const AiGeneratedField = ({ label, children }) => (
  <Box>
    <Typography
      sx={{
        color: "#475467",
        fontSize: "16px",
        fontWeight: 700,
        mb: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      {label}
      <Tooltip title="Generated by AI">
        <AutoAwesomeIcon sx={{ fontSize: 16, ml: 0.5, color: "darkgrey" }} />
      </Tooltip>
    </Typography>
    {children}
  </Box>
);
const AuditCapDetailsStatusOpen = ({ data }) => {
  return (
    <Grid container spacing={2}>
      {/* CAP Recommendation Section */}
      <Grid item xs={6} sx={{ pr: 4 }}>
        <Typography
          sx={{
            color: "#475467",
            fontSize: "30px",
            fontWeight: 500,
            textDecorationLine: "underline",
            my: 4,
            overflowWrap: "break-word",
            wordWrap: "break-word",
            hyphens: "auto",
          }}
        >
          CAP Recommendations
        </Typography>
        <AiGeneratedField label="Root Cause">
          <RecommendationsList
            title=""
            items={data?.rootCause ? String(data.rootCause).split("\n") : []}
          />
        </AiGeneratedField>
        <AiGeneratedField label="Immediate Corrective Action Plan">
          <RecommendationsList
            title=""
            items={
              data?.capRecommendations?.immediateCAP
                ? String(data.capRecommendations.immediateCAP).split("\n")
                : []
            }
          />
        </AiGeneratedField>
        <AiGeneratedField label="Long Term Preventative Action">
          <RecommendationsList
            title=""
            items={
              data?.capRecommendations?.longTermPreventativeAction
                ? String(
                    data.capRecommendations.longTermPreventativeAction
                  ).split("\n")
                : []
            }
          />
        </AiGeneratedField>
      </Grid>
      <Grid item xs={6}>
        <Typography
          sx={{
            color: "#475467",
            fontSize: "30px",
            fontWeight: 500,
            textDecorationLine: "underline",
            my: 4,
          }}
        >
          Site Input
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Accountable Person
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                fontStyle: "italic",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              {data?.responsiblePerson ||
                (data?.status === "SITE_ADDING_ACTION_PLAN" ||
                data?.status === "SITE_ADDED_ACTION_PLAN"
                  ? "Site filling this section"
                  : "To be filled by site")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                ...AuditStyles.fieldTitle,
                mb: 1,
              }}
            >
              Target Completion Date
            </Typography>
            <Typography sx={AuditStyles.auditReportFieldValue}>
              {data?.targetDeadline || "-"}
            </Typography>
          </Grid>
        </Grid>

        <Typography
          sx={{
            ...AuditStyles.fieldTitle,
            mb: 1,
            mt: 2,
          }}
        >
          Root Cause Response
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            fontStyle: "italic",
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {data?.rootCauseResponse ||
            (data?.status === "SITE_ADDING_ACTION_PLAN" ||
            data?.status === "SITE_ADDED_ACTION_PLAN"
              ? "Site filling this section"
              : "To be filled by site")}
        </Typography>

        <Typography
          sx={{
            ...AuditStyles.fieldTitle,
            mb: 1,
            mt: 2,
          }}
        >
          Immediate Corrective Action Plan
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            fontStyle: "italic",
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {data?.immediateCorrectiveActionPlanFacilityInput ||
            (data?.status === "SITE_ADDING_ACTION_PLAN" ||
            data?.status === "SITE_ADDED_ACTION_PLAN"
              ? "Site filling this section"
              : "To be filled by site")}
        </Typography>

        <Typography
          sx={{
            ...AuditStyles.fieldTitle,
            mb: 1,
            mt: 2,
          }}
        >
          Long Term Preventative Action Plan
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            fontStyle: "italic",
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {data?.longTermPreventativeActionPlanFacilityInput ||
            (data?.status === "SITE_ADDING_ACTION_PLAN" ||
            data?.status === "SITE_ADDED_ACTION_PLAN"
              ? "Site filling this section"
              : "To be filled by site")}
        </Typography>

        <Typography
          sx={{
            color: "#475467",
            fontSize: "30px",
            fontWeight: 500,
            textDecorationLine: "underline",
            my: 4,
          }}
        >
          CAP Update
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography
              sx={{
                ...AuditStyles.fieldTitle,
                mb: 1,
              }}
            >
              Status
            </Typography>
            <Box sx={ComponentStyles.alignBoxItemsStart}>
              <CustomChipLabel
                cap={data.statusChipLabel}
                capLabel={data.status}
              />
              <Box sx={{ minWidth: "8px" }}></Box>
              {/* {data.status &&
                !data.status.includes("Closed") &&
                data.timeline && (
                  <CustomChipLabel cap={"default"} capLabel={data.timeline} />
                )} */}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                ...AuditStyles.fieldTitle,
                mb: 1,
              }}
            >
              Actual Completion Date
            </Typography>
            <Typography sx={AuditStyles.auditReportFieldValue}>
              {data?.completionDate || "-"}
            </Typography>
          </Grid>
        </Grid>

        <Typography
          sx={{
            ...AuditStyles.fieldTitle,
            mt: 3,
          }}
        >
          Verification Note
        </Typography>
        <Typography sx={AuditStyles.auditReportFieldValue}>
          {data?.verificationNote || "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AuditCapDetailsStatusOpen;
