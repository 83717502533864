import React from "react";
import { Box, Tabs, Tab, Badge } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  selectCountOfUnreadNotifications,
  selectCountOfUnreadSupplierNotes,
  selectNotificationTabs,
} from "../../selectors/notification.selector";
import { setNotificationTabs } from "../../reducers/notification.reducer";

const NotificationTabs = () => {
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    dispatch(setNotificationTabs(newValue));
  };
  const value = useSelector(selectNotificationTabs);
  const unreadNotesCount = useSelector(selectCountOfUnreadSupplierNotes);
  const unreadNotificationsCount = useSelector(
    selectCountOfUnreadNotifications
  );
  return (
    <Box sx={{ width: "100%", px: 2, flexGrow: 1, px: 4 }}>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        sx={{ textDecoration: "none" }}
      >
        <Tab
          value="notes"
          label={
            <Badge
              badgeContent={unreadNotesCount}
              color="error"
              sx={{ "& .MuiBadge-badge": { right: -15 } }}
            >
              Supplier Notes
            </Badge>
          }
          sx={{ textTransform: "none" }}
        />
        <Tab
          value="notifications"
          label={
            <Badge
              badgeContent={unreadNotificationsCount}
              color="error"
              sx={{ "& .MuiBadge-badge": { right: -15 } }}
            >
              Notifications
            </Badge>
          }
          sx={{ textTransform: "none" }}
        />
      </Tabs>
    </Box>
  );
};

export default NotificationTabs;
