import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectFactoryData } from "../../selectors/factory.selector";
import { getListOfAllAudits, getAuditById } from "../../reducers/audit.reducer";
import RiskCard from "../riskCard.component";
import FacilityIssuesCard from "./facilityIssuesCard.component";
import PastCAP from "./factoryActionNeeded.component";
import FactoryActionNeeded from "./factoryActionNeeded.component";
import TwoLineCard from "../twoLineCard.component";
import { FacilityStyles } from "../../styles";
import { calculateAuditDueDataData } from "../../utils/lib";

const FactoryMostRecentAuditDetails = ({ selectedFactory }) => {
  const isInactive = selectedFactory?.metadata?.status === "inactive";
  const auditDueData = {
    nextAuditDueDate: selectedFactory?.metadata?.auditCadence?.nextAuditDueDate || "N/A",
    isPastDue: false,
  }

  return (
    <>
      <Typography sx={FacilityStyles.mostRecentAuditHeading}>
        Details from Most Recent Audit
      </Typography>
      {selectedFactory?.latestAudit?.state !== "active" ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "50px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 16,
              color: "#8B8D97",
            }}
          >
            No audit exists for site status
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <FacilityIssuesCard latestAuditData={selectedFactory.latestAudit} />
          </Grid>
          <Grid item xs={4}>
            <TwoLineCard
              title="Audit Score"
              nextAuditDate={selectedFactory?.latestAudit?.risk?.auditScore || "Coming Soon."}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default FactoryMostRecentAuditDetails;
