import React from "react";
import { Box, Typography, List, ListItem, Link } from "@mui/material";

const DisplayUploadedProof = ({ uploadedProof, displayTitle = true }) => {
  return (
    <Box
      sx={{
        padding: "12px",
        borderRadius: "12px",
        background: "#b6b6b614",
      }}
    >
      {displayTitle && (
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 700,
            pl: 1,
          }}
        >
          Uploaded Files:{" "}
        </Typography>
      )}
      <List sx={{ p: 0 }}>
        {uploadedProof?.map((proof, index) => (
          <ListItem key={index} sx={{ p: 1 }}>
            <Link
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#45464E",
                textDecoration: proof.url ? "underline" : "none",
                textDecorationColor: proof.url ? "#45464E" : "transparent",
                "&:hover": {
                  textDecorationColor: proof.url
                    ? "rgba(69, 70, 78, 0.8)"
                    : "transparent",
                },
                pointerEvents: proof.url ? "auto" : "none",
                cursor: proof.url ? "pointer" : "default",
              }}
              href={proof.url || "#"}
              target="_blank"
            >
              {proof.name}
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default DisplayUploadedProof;
