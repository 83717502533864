import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Divider,
  Button,
} from "@mui/material";
import { ComponentStyles } from "../../styles";

const FactoryActionNeeded = ({ capDetails }) => {

  return (
    <>
      <Typography sx={{ fontWeight: 700, fontSize: 18, color: "#000", my: 3 }}>
        Action Needed
      </Typography>

      <Box
        elevation={1}
        sx={{
          py: 2,
          px: 4,
          border: "1px solid #EAECF0",
          borderRadius: "7px",
          border: "1px solid #D0D5DD",
          background: "#FFF",
          boxShadow:
            "box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
          mb: 4,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Open CAP
        </Typography>
        <Divider sx={{ my: "10px" }} />
        <Table>
          <TableBody>
            {capDetails.map((cap, index) => (
              <TableRow key={index} sx={{ pb: "10px" }}>
                <TableCell sx={{ borderBottom: "none", pl: 0, py: 0 }}>
                  <Typography sx={ComponentStyles.fieldName}>Status</Typography>
                  <Box
                    sx={{
                      color: cap.key === "past" ? "#FFF" : "#45464E",
                      textTransform: "none",
                      padding: "3px 8px",
                      fontSize: "12px",
                      fontWeight: 500,
                      borderRadius: "6px",
                      border: "1px solid #D0D5DD",
                      background: cap.dueInDays > 30 ? "darkgray" : cap.dueInDays > 0 ? "#FF692E" : "#B42318",
                      width: "fit-content",
                      color: "white",
                    }}
                  >
                    {cap.dueInDays > 0
                      ? `In ${Math.ceil(cap.dueInDays)} days`
                      : `Past Due ${Math.ceil(-cap.dueInDays)} days`}
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    ...ComponentStyles.fieldName,
                    borderBottom: "none",
                    pb: 0,
                  }}
                >
                  <Typography sx={ComponentStyles.fieldName}>
                    Severity
                  </Typography>
                  <Typography sx={ComponentStyles.fieldValue}>
                    {cap.severity}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    ...ComponentStyles.fieldName,
                    borderBottom: "none",
                    pb: 0,
                  }}
                >
                  <Typography sx={ComponentStyles.fieldName}>Findings</Typography>
                  <Typography sx={ComponentStyles.fieldValue}>
                    {cap.issueSummary.length > 70
                      ? `${cap.issueSummary.substring(0, 70)}...`
                      : cap.issueSummary}
                  </Typography>
                </TableCell>
                {/* <TableCell sx={{ borderBottom: "none", pl: 0, py: 1 }}>
                  <Button
                    variant="text"
                    sx={{ color: "#1976d2", textTransform: "none" }}
                  >
                    Details
                  </Button>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

// Example usage

export default FactoryActionNeeded;
