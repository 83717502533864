import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";
import { selectFactoryData } from "../../selectors/factory.selector";
import { selectAuditData } from "../../selectors/audit.selector";

const FactoryDetails = () => {
  const selectedAuditData = useSelector(selectAuditData);
  const selectedFactoryData = useSelector(selectFactoryData);

  return (
    <List sx={{ width: "100%", pl: 3, pr: 1 }}>
      <ListItem>
        <ListItemText
          primary="Site Name"
          secondary={selectedFactoryData?.name || "-"}
          primaryTypographyProps={{
            style: {
              color: "#475467",
              fontSize: 16,
              fontWeight: 700,
            },
          }}
          secondaryTypographyProps={{
            style: {
              color: "#45464E",
              fontSize: 16,
              fontWeight: 400,
            },
          }}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Address"
          secondary={
            selectedAuditData?.metadata?.facilityData?.facility_address || "-"
          }
          primaryTypographyProps={{
            style: {
              color: "#475467",
              fontSize: 16,
              fontWeight: 700,
            },
          }}
          secondaryTypographyProps={{
            style: {
              color: "#45464E",
              fontSize: 16,
              fontWeight: 400,
            },
          }}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Audit End Date"
          secondary={
            selectedAuditData?.metadata?.facilityData?.audit_end || "-"
          }
          primaryTypographyProps={{
            style: {
              color: "#475467",
              fontSize: 16,
              fontWeight: 700,
            },
          }}
          secondaryTypographyProps={{
            style: {
              color: "#45464E",
              fontSize: 16,
              fontWeight: 400,
            },
          }}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Auditor Name"
          secondary={
            selectedAuditData?.metadata?.facilityData?.auditor_name || "-"
          }
          primaryTypographyProps={{
            style: {
              color: "#475467",
              fontSize: 16,
              fontWeight: 700,
            },
          }}
          secondaryTypographyProps={{
            style: {
              color: "#45464E",
              fontSize: 16,
              fontWeight: 400,
            },
          }}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Audit Firm"
          secondary={
            selectedAuditData?.metadata?.facilityData?.auditing_firm || "-"
          }
          primaryTypographyProps={{
            style: {
              color: "#475467",
              fontSize: 16,
              fontWeight: 700,
            },
          }}
          secondaryTypographyProps={{
            style: {
              color: "#45464E",
              fontSize: 16,
              fontWeight: 400,
            },
          }}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Audit Scheme"
          secondary={
            selectedAuditData?.metadata?.facilityData?.auditing_standard || "-"
          }
          primaryTypographyProps={{
            style: {
              color: "#475467",
              fontSize: 16,
              fontWeight: 700,
            },
          }}
          secondaryTypographyProps={{
            style: {
              color: "#45464E",
              fontSize: 16,
              fontWeight: 400,
            },
          }}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Audit Score"
          secondary={selectedAuditData?.risk?.auditScore || "-"}
          primaryTypographyProps={{
            style: {
              color: "#475467",
              fontSize: 16,
              fontWeight: 700,
            },
          }}
          secondaryTypographyProps={{
            style: {
              color: "#45464E",
              fontSize: 16,
              fontWeight: 400,
            },
          }}
        />
      </ListItem>
    </List>
  );
};

export default FactoryDetails;
