import React from 'react';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Box,
  Badge,
} from "@mui/material";
import { ComponentStyles } from "../../styles";

const NavItem = ({ 
  icon, 
  label, 
  isSelected, 
  isExpanded, 
  onClick, 
  badge,
  ...props 
}) => {
  return (
    <Tooltip title={!isExpanded ? label : ""} placement="right">
      <ListItemButton
        onClick={onClick}
        selected={isSelected}
        sx={{
          bgcolor: isSelected ? "action.selected" : "inherit",
          borderRadius: "12px",
          marginY: 0.5,
          justifyContent: isExpanded ? "flex-start" : "center",
          minWidth: 0,
          pl: 2,
        }}
        {...props}
      >
        <ListItemIcon 
          sx={{ 
            minWidth: isExpanded ? 40 : 24,
            mr: isExpanded ? 2 : 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '24px',
          }}
        >
          <img
            src={icon}
            alt={label}
            style={{
              ...ComponentStyles.navbarListItemIcon,
              margin: 0,
            }}
          />
        </ListItemIcon>
        {isExpanded && (
          <ListItemText
            primaryTypographyProps={{
              sx: ComponentStyles.navbarListItemText,
            }}
            primary={
              badge ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{label}</span>
                  <Badge
                    badgeContent={badge}
                    color="error"
                    sx={{ marginLeft: 2, mr: 0 }}
                  />
                </Box>
              ) : (
                label
              )
            }
          />
        )}
      </ListItemButton>
    </Tooltip>
  );
};

export default NavItem; 