import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";

const selectAudit = (state) => state.audit;

export const selectAuditList = createSelector(
  [selectAudit],
  (audit) => audit.audits
);

export const selectAuditListForFactory = createSelector(
  [selectAudit, (_, factoryId) => factoryId],
  (audit, factoryId) =>
    factoryId
      ? audit.audits.filter(
          (audit) => audit.factoryId.toString() === factoryId.toString()
        )
      : audit.audits
);

export const selectCapListForFactory = createSelector(
  [selectAudit, (_, factoryId) => factoryId],
  (audit, factoryId) => {
    const audits = factoryId
      ? audit.audits.filter(
          (audit) => audit.factoryId.toString() === factoryId.toString()
        )
      : audit.audits;

    const CapList = audits
      .filter((audit) => audit.state === "active")
      .flatMap(
        (audit) =>
          audit.issueDetails?.capDetails?.map((capDetails) => ({
            auditId: audit.auditId,
            ...capDetails,
          })) || []
      );

    return CapList;
  }
);

export const selectMostRecentAuditIdForFactory = createSelector(
  [selectAudit, (_, factoryId) => factoryId],
  (audit, factoryId) =>
    factoryId
      ? audit.audits.filter(
          (audit) => audit.factoryId.toString() === factoryId.toString()
        )[0]?.auditId
      : audit.audits[0]?.auditId
);

export const selectAuditNameFromId = createSelector(
  [selectAudit, (_, id) => id],
  (audit, id) => {
    if (id && id !== "" && audit.audits.length > 0) {
      const auditName = audit.audits.filter(
        (audit) => audit.auditId.toString() === id.toString()
      );
      return auditName[0]?.metadata?.auditType;
    } else {
      return null;
    }
  }
);

export const selectAuditData = createSelector(
  [selectAudit],
  (audit) => audit.auditData
);
export const selectSelectedAuditIssueId = createSelector(
  [selectAudit],
  (audit) => audit.selectedAuditIssueId
);

export const selectAuditXlsxFile = createSelector([selectAudit], (audit) =>
  audit.auditData?.files?.filter((file) => file.type === "xlsx")
);

export const selectAuditPageTab = createSelector(
  [selectAudit],
  (audit) => audit.auditPageTab
);

export const selectAuditReportPageTab = createSelector(
  [selectAudit],
  (audit) => audit.auditReportPageTab
);

export const selectAuditIssuesData = createSelector([selectAudit], (audit) => {
  const issueDetails = audit.auditData?.issueDetails?.issueDetails || {};
  const severityCategories =
    audit.auditData?.issueDetails?.severityCategories || {};

  const detailedIssues = Object.keys(severityCategories).map((category) => ({
    category,
    open: issueDetails.open?.[category] || 0,
    pastDue: issueDetails.pastDue?.[category] || 0,
    totalClosed: issueDetails.closed?.[category] || 0,
    total: issueDetails.totalBySeverity?.[category] || 0,
    ci: issueDetails.ci?.[category] || 0,
    color: severityCategories[category],
  }));

  const issuesSummary = {
    open: issueDetails.Open,
    pastDue: issueDetails.PastDue,
    ci: issueDetails.CI,
    totalClosed: issueDetails.Closed,
    total: issueDetails.total,
  };

  return {
    issuesSummary,
    detailedIssues,
  };
});

export const selectAuditIssueCAPData = createSelector(
  [selectAudit],
  (audit) => {
    const capStartDate = audit?.auditData?.metadata?.facilityData?.cap_start_date
      ? new Date(audit?.auditData?.metadata?.facilityData?.cap_start_date)
      : moment(audit?.auditData?.createdAt).isValid()
        ? new Date(audit?.auditData?.createdAt)
        : null;
    const currentDate = new Date();

    return audit?.auditIssueCAPData
      ? Object.values(audit.auditIssueCAPData).map((issue) => {
          const timelineDays = issue.timeline?.match(/\d+/);
          const timelineNumber = timelineDays
            ? parseInt(timelineDays[0], 10)
            : 0;

          const dueInDays = capStartDate
            ? Math.floor(
                (capStartDate.getTime() +
                  timelineNumber * 24 * 60 * 60 * 1000 -
                  currentDate.getTime()) /
                  (24 * 60 * 60 * 1000)
              )
            : null;

          return {
            id: issue.id,
            clientCodeTitle: issue.clientCodeTitle,
            issue: issue.issueSummary,
            issueDetail: issue.issueDetail,
            auditBasis: issue.auditBasis,
            type: issue.type,
            severity: issue.severity,
            statusChipLabel: issue.statusChipLabel,
            status: issue.status,
            timeline: issue.timeline,
            note: issue.note || null,
            dueInDays: dueInDays,
          };
        })
      : [];
  }
);

export const selectAuditIssueCAPDataGroupedByType = createSelector(
  [selectAudit],
  (audit) => {
    if (audit.auditIssueCAPData) {
      const data = Object.values(audit.auditIssueCAPData).reduce(
        (acc, issue) => {
          acc[issue.type] = acc[issue.type] || [];
          acc[issue.type].push({ id: issue.id, issue: issue.issueSummary });
          return acc;
        },
        {}
      );

      return data;
    } else {
      return {};
    }
  }
);

export const selectAuditIssueDetails = createSelector(
  [selectAudit],
  (audit) => {
    const selectedIssueId = audit.selectedAuditIssueId;

    return selectedIssueId && audit.auditIssueCAPData[selectedIssueId]
      ? audit.auditIssueCAPData[selectedIssueId]
      : {};
  }
);

export const selectFirstAuditIssueId = createSelector(
  [selectAudit],
  (audit) => {
    return Object.keys(audit.auditIssueCAPData)[0];
  }
);
export const selectListOfAuditsInCapManagement = createSelector(
  [selectAudit],
  (audit) => audit.listOfAuditsInCapManagement
);

export const selectAuditsWhichAreInProgressOfCapManagement = createSelector(
  [selectAudit],
  (audit) => {
    let auditsInProgress = {};
    audit?.listOfAuditsInCapManagement?.forEach((audit) => {
      auditsInProgress[audit.auditId] = true;
    });
    return auditsInProgress;
  }
);

export const selectFactoriesWhichAreInProgressOfCapManagement = createSelector(
  [selectAudit],
  (audit) => {
    let factoriesInProgress = {};
    audit.listOfAuditsInCapManagement.forEach((audit) => {
      factoriesInProgress[audit.factoryId] = true;
    });
    return factoriesInProgress;
  }
);

export const selectNumberOfInProgressCAPs = createSelector(
  [selectAudit],
  (audit) => audit.listOfAuditsInCapManagement?.length
);

export const selectCurrentStepInCapManagement = createSelector(
  [selectAudit],
  (audit) => audit.auditData?.capManagementStatus
);
export const selectAuditIssueCapDataStatus = createSelector(
  [selectAudit],
  (audit) => {
    const auditCAPStatus = audit.auditData?.capManagementStatus;

    let completed = 0;
    let total = Object.keys(audit.auditIssueCAPData).length;

    Object.keys(audit.auditIssueCAPData).forEach((issueId) => {
      if (
        auditCAPStatus === "IN_PROGRESS" &&
        audit.auditIssueCAPData[issueId]?.status?.trim() ===
          "SITE_ADDED_ACTION_PLAN"
      ) {
        completed++;
      } else if (
        auditCAPStatus === "BRAND_REVIEWING_PLAN" &&
        audit.auditIssueCAPData[issueId]?.status?.trim() ===
          "BRAND_APPROVED_PLAN"
      ) {
        completed++;
      } else if (
        auditCAPStatus === "BRAND_APPROVED_PLAN" &&
        (audit.auditIssueCAPData[issueId]?.status?.trim() ===
          "SITE_ADDED_PROOF" ||
          audit.auditIssueCAPData[issueId]?.status?.trim() ===
            "BRAND_APPROVED_PROOF")
      ) {
        completed++;
      } else if (
        auditCAPStatus === "BRAND_APPROVING_PROOF" &&
        audit.auditIssueCAPData[issueId]?.status?.trim() ===
          "BRAND_APPROVED_PROOF"
      ) {
        completed++;
      } else if (
        auditCAPStatus === "BRAND_APPROVING_PROOF" &&
        audit.auditIssueCAPData[issueId]?.status?.trim() === "CLOSED"
      ) {
        completed++;
      }
    });
    return {
      completed,
      total,
    };
  }
);

export const selectCaseForSecondSubStepper = createSelector(
  [selectAudit],
  (audit) => {
    const auditCAPStatus = audit.auditData?.capManagementStatus;

    let brandApprovedProof = 0;
    let siteAddedProof = 0;
    let total = Object.keys(audit.auditIssueCAPData).length;

    if (
      auditCAPStatus === "BRAND_APPROVED_PLAN" ||
      auditCAPStatus === "BRAND_REVIEWING_PLAN"
    ) {
      Object.keys(audit.auditIssueCAPData).forEach((issueId) => {
        if (
          audit.auditIssueCAPData[issueId]?.status?.trim() ===
            "BRAND_APPROVED_PROOF" ||
          audit.auditIssueCAPData[issueId]?.status?.trim() === "CLOSED" ||
          audit.auditIssueCAPData[issueId]?.status?.trim() ===
            "CONTINUOUS_IMPROVEMENT"
        ) {
          brandApprovedProof++;
        } else if (
          audit.auditIssueCAPData[issueId]?.status?.trim() ===
          "SITE_ADDED_PROOF"
        ) {
          siteAddedProof++;
        }
      });
    }
    let showSecondSubStepper = false;
    let showButtonOnCircularProgress = true;
    if (brandApprovedProof > 0) {
      showSecondSubStepper = true;
    }
    if (siteAddedProof > 0 || brandApprovedProof > 0) {
      showButtonOnCircularProgress = false;
    }

    return {
      brandApprovedProof,
      siteAddedProof,
      total,
      showSecondSubStepper,
      showButtonOnCircularProgress,
    };
  }
);

export const selectAuditCAPStatus = createSelector(
  [selectAudit],
  (audit) => audit.auditData?.capManagementStatus
);

export const selectAuditCAPStatusUploadedProof = createSelector(
  [selectAudit],
  (audit) => {
    const issueId = audit.selectedAuditIssueId;
    const issue = audit.auditIssueCAPData[issueId];
    return issue?.status === "SITE_ADDED_PROOF";
  }
);
export const selectAuditCAPUploadedProof = createSelector(
  [selectAudit],
  (audit) => {
    const selectedIssueId = audit.selectedAuditIssueId;
    return audit.auditIssueCAPData[selectedIssueId]?.proofDocuments || [];
  }
);

export const selectResources = createSelector([selectAudit], (audit) => {
  return audit.resources;
});

export const selectNonActiveAuditCount = createSelector(
  [selectAudit],
  (audit) => audit.nonActiveAuditCount
);
