import { all, put, select, takeLatest } from "redux-saga/effects";

import {
  selectAuthToken,
  selectOrganisationId,
} from "../selectors/login.selector";
import * as API from "../utils/api";
import axios from "axios";
import {
  getDueAudits,
  getDueCaps,
  getDueCICaps,
  setDueAudits,
  setDueCaps,
  setDueCICaps,
} from "../reducers/dueAction.reducer";

function* getDueCapsWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.ORGANISATIONS.GET_DUE_CAPS.replace(
    "<ORGANISATION_ID>",
    organisationId
  );

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  try {
    const response = yield axios.get(requestURL, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setDueCaps(response.data.results));
      }
    }
  } catch (error) {}
}

function* getDueCICapsWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.ORGANISATIONS.GET_DUE_CI_AUDITS.replace(
    "<ORGANISATION_ID>",
    organisationId
  );

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  try {
    const response = yield axios.get(requestURL, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setDueCICaps(response.data.results));
      }
    }
  } catch (error) {}
}

function* getDueAuditsWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.ORGANISATIONS.GET_DUE_AUDITS.replace(
    "<ORGANISATION_ID>",
    organisationId
  );

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  try {
    const response = yield axios.get(requestURL, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setDueAudits(response.data.results));
      }
    }
  } catch (error) {}
}

function* watchGetDueCapsSaga() {
  yield takeLatest(getDueCaps.type, getDueCapsWorker);
}

function* watchGetDueAuditsSaga() {
  yield takeLatest(getDueAudits.type, getDueAuditsWorker);
}

function* watchgetDueCICapsSaga() {
  yield takeLatest(getDueCICaps.type, getDueCICapsWorker);
}

export default function* rootSaga() {
  yield all([
    watchGetDueCapsSaga(),
    watchGetDueAuditsSaga(),
    watchgetDueCICapsSaga(),
    watchGetDueAuditsSaga(),
  ]);
}
