import { all, put, select, takeLatest, call } from "redux-saga/effects";
import {
  submitLoginRequest,
  loginSuccess,
  loginFailure,
  logout,
  getOrganisationResources,
  setOrganisationResources,
  pinResource,
  togglePinnedStatusOfResource,
} from "../reducers/login.reducer";
import {
  selectAuthToken,
  selectEmail,
  selectOrganisationId,
} from "../selectors/login.selector";
import * as API from "../utils/api";
import axios from "axios";
import {
  getNotes,
  getNotifications,
  markNoteAsReadOrUnread,
  markNotificationAsReadOrUnread,
  setNotes,
  setNotifications,
  triggerMarkNoteAsReadOrUnread,
  triggerMarkNotificationAsReadOrUnread,
} from "../reducers/notification.reducer";

function* handleLogin(action) {
  try {
    const email = yield select(selectEmail);
    if (email) {
      const requestURL = API.USER.LOGIN;

      const headers = {
        "Content-Type": "application/json",
      };

      const body = {
        email,
        password: action.payload.password,
      };

      const response = yield axios.post(requestURL, body, { headers });

      if (response && response.status === 200 && response.data) {
        const {
          data: { success, results, message },
        } = response;

        if (success) {
          // Check if supplierData exists
          if (
            !email.includes("4imprint") &&
            results.supplierData &&
            results.supplierData.length > 0
          ) {
            // If supplierData exists, dispatch login failure
            yield put(
              loginFailure(
                "Incorrect username or password. If you are a supplier, please login at suppliers.esger.co"
              )
            );
          } else {
            yield put(loginSuccess(results));
            localStorage.setItem("loginState", JSON.stringify(results));
          }
        } else {
          const errorMessage = mapErrorMessage(message);
          yield put(loginFailure(errorMessage));
        }
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      yield put(loginFailure("Session expired. Please log in again."));
      yield put(logout()); // Dispatch logout to reset state and storage
    } else if (error.message === "Network Error") {
      yield put(
        loginFailure(
          "Network error. Please check your connection and try again."
        )
      );
    } else {
      yield put(loginFailure(error.toString()));
    }
  }
}
function* handleGetOrganisationResourcesWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);
    const organisationId = yield select(selectOrganisationId);

    const requestURL = API.ORGANISATIONS.GET_RESOURCES.replace(
      "<ORGANISATION_ID>",
      organisationId
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    const response = yield axios.get(requestURL, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setOrganisationResources(response.data.results.resources));
      }
    }
  } catch (error) {
    console.error("Failed to fetch organisation resources:", error);
  }
}

function* handleTogglePinnedStatusOfResourceWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);
    const organisationId = yield select(selectOrganisationId);

    const requestURL = API.ORGANISATIONS.PIN_RESOURCE.replace(
      "<ORGANISATION_ID>",
      organisationId
    ).replace("<RESOURCE_ID>", action.payload.resourceId);

    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    const body = {
      isPinned: action.payload.isPinned,
    };

    const response = yield axios.post(requestURL, body, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setOrganisationResources(response.data.results.resources));
      }
    }
  } catch (error) {
    console.error("Failed to pin resource:", error);
  }
}

function mapErrorMessage(message) {
  const errorMapping = {
    "User does not exist.": "Incorrect username or password",
    // Add more mappings as needed
  };
  return errorMapping[message] || "An error occurred. Please try again.";
}
function* watchLoginSaga() {
  yield takeLatest(submitLoginRequest.type, handleLogin);
}

function* handleGetOrganisationResourcesSaga() {
  yield takeLatest(
    getOrganisationResources.type,
    handleGetOrganisationResourcesWorker
  );
}

function* handleTogglePinnedStatusOfResourceSaga() {
  yield takeLatest(
    togglePinnedStatusOfResource.type,
    handleTogglePinnedStatusOfResourceWorker
  );
}

export default function* rootSaga() {
  yield all([
    watchLoginSaga(),
    handleGetOrganisationResourcesSaga(),
    handleTogglePinnedStatusOfResourceSaga(),
  ]);
}
