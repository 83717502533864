import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectFactoryActionNeededData,
  selectFactoryData,
} from "../../selectors/factory.selector";
import { selectAuditListForFactory, selectCapListForFactory } from "../../selectors/audit.selector";

import FactoryActionNeeded from "./factoryActionNeeded.component";

import FactoryMostRecentAuditDetails from "./factoryMostRecentAuditDetails.component";
import FacilityAuditScheduling from "./facilityAuditScheduling.component";

const FacilityComplianceStatus = () => {

  const params = useParams();
  const selectFactory = useSelector(selectFactoryData);

  const factoryCapData = useSelector((state) =>
    selectCapListForFactory(state, params.factoryId)
  );

  // Filter out items with status "Open"
  const openCapData = factoryCapData.filter(item => item.status === "Open");



  return (
    <>
      <FacilityAuditScheduling selectedFactory={selectFactory} />

      <FactoryMostRecentAuditDetails selectedFactory={selectFactory} />

      {/* {selectFactory?.latestAudit?.state === "active" ? (
        <FactoryMostRecentAuditDetails selectedFactory={selectFactory} />
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "50px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 16,
              color: "#8B8D97",
            }}
          >
            No audit exist for site status
          </Typography>
        </Box>
      )} */}
      <Grid item>
        {openCapData?.length > 0 && (
          <FactoryActionNeeded capDetails={openCapData} />
        )}
      </Grid>
    </>
  );
};

export default FacilityComplianceStatus;
