import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress, Button } from "@mui/material";
import {
  selectAuditCAPStatus,
  selectAuditData,
  selectAuditIssueCapDataStatus,
  selectCaseForSecondSubStepper,
} from "../../selectors/audit.selector";
import { useDispatch, useSelector } from "react-redux";
import { updateIssueData } from "../../reducers/audit.reducer";
import { useParams } from "react-router-dom";

const CAPProgressCircular = ({ secondSubStepper = null }) => {
  const params = useParams();
  const [progress, setProgress] = useState(2); // Starting progress: 2 out of 5
  const auditIssueCapDataStatus = useSelector(selectAuditIssueCapDataStatus);
  const caseForSecondSubStepper = useSelector(selectCaseForSecondSubStepper);

  if (caseForSecondSubStepper.showSecondSubStepper) {
    auditIssueCapDataStatus.completed =
      caseForSecondSubStepper.siteAddedProof +
      caseForSecondSubStepper.brandApprovedProof;
  }
  if (secondSubStepper) {
    auditIssueCapDataStatus.completed = secondSubStepper.brandApprovedProof;
  }

  // Handler to simulate increasing progress
  const incrementProgress = () => {
    if (progress < 5) {
      setProgress(progress + 1);
    }
  };
  const dispatch = useDispatch();
  const selectedAuditData = useSelector(selectAuditData);
  const handleRequestCAPApproval = () => {
    dispatch(
      updateIssueData({
        auditId: params?.auditId,
        changes: { submitTriggered: true },
      })
    );
  };
  const handleRequestProofApproval = () => {
    dispatch(
      updateIssueData({
        auditId: params?.auditId,
        changes: { submitTriggeredForProof: true },
      })
    );
  };
  const auditCAPStatus = useSelector(selectAuditCAPStatus);
  const steps = [
    { label: "4imprint Audit Review" },
    { label: "Supplier CAP Input" },
    { label: "4imprint CAP Review" },
    { label: "Supplier Additional Information Completed" },
    { label: "4imprint CAP Closure Review" },
    { label: "4imprint CAP Closure Review" },
  ];
  const mappingOfCAPStatusToStep = {
    OPEN: 0,
    IN_PROGRESS: 1,
    BRAND_REVIEWING_PLAN: 2,
    BRAND_APPROVED_PLAN: 3,
    SITE_ADDING_PROOF: 4,
    BRAND_APPROVING_PROOF: 4,
    SITE_ADDED_PROOF: 5,
    BRAND_APPROVED_PROOF: 5,
    CLOSED: 5,
  };
  const currentStep = mappingOfCAPStatusToStep[auditCAPStatus];

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ p: 4 }}
    >
      {/* Title */}
      <Typography
        sx={{
          mb: 2,
          fontSize: "18px",
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        {secondSubStepper
          ? "Number of Proofs Approved By the Brand"
          : steps[currentStep]?.label}
      </Typography>

      {/* Circular progress with custom styling */}
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={100} // Set to 100% to create full circle background
          size={100}
          thickness={6}
          sx={{ color: "#E3F2FD" }} // Light blue color for the entire circle
        />
        <CircularProgress
          variant="determinate"
          value={
            ((auditIssueCapDataStatus.completed || 0) /
              auditIssueCapDataStatus.total) *
            100
          }
          size={100}
          thickness={6}
          sx={{
            color: "#3F51B5",
            position: "absolute",
            left: 0,
            visibility:
              auditIssueCapDataStatus.completed > 0 ? "visible" : "hidden",
          }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {/* Display the current step out of total steps */}
          <Typography variant="h5" component="div" color="text.primary">
            {auditIssueCapDataStatus.completed || 0} /
            {auditIssueCapDataStatus.total}
          </Typography>
        </Box>
      </Box>

      {/* Button, disabled unless progress is 5/5 */}
      {/* {caseForSecondSubStepper.showButtonOnCircularProgress && (
        <Button
          variant="contained"
          onClick={() => {
            currentStep === 1
              ? handleRequestCAPApproval()
              : handleRequestProofApproval();
          }}
          sx={{
            textTransform: "none",
            mt: 2,
            width: "200px",
            backgroundColor:
              auditIssueCapDataStatus.completed ===
              auditIssueCapDataStatus.total
                ? "#3F51B5"
                : "#E0E0E0", // Custom color when disabled
          }}
          disabled={
            auditIssueCapDataStatus.completed !== auditIssueCapDataStatus.total
          } // Disable until progress reaches 5
        >
          {currentStep === 1
            ? "Request CAP Approval"
            : currentStep === 2
            ? "Waiting for approval"
            : currentStep === 3
            ? "Request Proof Approval"
            : auditCAPStatus === "BRAND_APPROVING_PROOF" ||
              auditCAPStatus === "SITE_ADDED_PROOF"
            ? "Waiting for proof approval"
            : null}
        </Button>
      )} */}

      {/* <Button variant="outlined" sx={{ mt: 2 }} onClick={incrementProgress}>
        Increment Progress
      </Button> */}
    </Box>
  );
};

export default CAPProgressCircular;
