import { createSelector } from "@reduxjs/toolkit";

const selectNotification = (state) => state.notification;

export const selectLoadingState = createSelector(
  [selectNotification],
  (notifications) => notifications.loading
);

export const selectSupplierNotes = createSelector(
  [selectNotification],
  (notifications) =>
    [...(notifications?.notes || [])]?.sort((a, b) => {
      // Sort by isRead: false first, then true
      if (a.isRead === b.isRead) return 0;
      return a.isRead ? 1 : -1;
    })
);

export const selectCountOfUnreadSupplierNotes = createSelector(
  [selectNotification],
  (notifications) => notifications.notes.filter((note) => !note.isRead).length
);

export const selectNotifications = createSelector(
  [selectNotification],
  (notifications) =>
    [...(notifications?.notifications || [])]?.sort((a, b) => {
      // Sort by isRead: false first, then true
      if (a.isRead === b.isRead) return 0;
      return a.isRead ? 1 : -1;
    })
);

export const selectCountOfUnreadNotifications = createSelector(
  [selectNotification],
  (notifications) =>
    notifications.notifications.filter((note) => !note.isRead).length
);

export const selectNotificationTabs = createSelector(
  [selectNotification],
  (notifications) => notifications.notificationTabs
);

export const selectCountOfTotalUnreadNotifications = createSelector(
  [selectNotification],
  (notifications) => {
    let unreadNotifications = notifications.notifications.filter(
      (notification) => !notification.isRead
    ).length;
    let unreadSupplierNotes = notifications.notes.filter(
      (note) => !note.isRead
    ).length;
    return unreadNotifications + unreadSupplierNotes;
  }
);
