import React, { useEffect, useState } from "react";
import { Box, Typography, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectFactoryList } from "../../selectors/factory.selector";
import {
  getFactoryById,
  getListOfAllFactories,
} from "../../reducers/factory.reducer";
import { ScatterChart } from "@mui/x-charts/ScatterChart";

const RiskScatterPlot = () => {
  const dispatch = useDispatch();
  const facilityList = useSelector(selectFactoryList);

  useEffect(() => {
    dispatch(getListOfAllFactories({}));
  }, []); 

  const [selectedFacilityId, setSelectedFacilityId] = useState("");
  const [selectedSupplierId, setSeletectedSupplierId] = useState("");
  const [yAxisMax, setYAxisMax] = useState(10);

  const data = facilityList.map((facility) => ({
    id: facility?.factoryId,
    x: facility?.auditScore,
    y: Number(((facility?.spend || 0) / 1000000).toFixed(5)),
    supplierId: facility?.supplierId,
    facilityName: facility?.name,
    spend: facility?.spend,
  }));

  const filteredData = data.filter((item) => item.x !== 0);

  const handleClick = (event, itemData) => {
    event.preventDefault();
    const facilityId = filteredData[itemData.dataIndex].id;
    const supplierId = filteredData[itemData.dataIndex].supplierId;
    setSelectedFacilityId(facilityId);
    setSeletectedSupplierId(supplierId);
  };

  useEffect(() => {
    dispatch(
      getFactoryById({
        supplierId: selectedSupplierId,
        factoryId: selectedFacilityId,
      })
    );
  }, [selectedSupplierId, selectedFacilityId]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        maxHeight: "60vh",
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, pr: 2 }}>
        <Box>
          <Typography variant="h4" sx={{ color: "#182230", fontWeight: 500, fontSize: "36px" }}>
            Site Risk Map
          </Typography>
        </Box>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Y-Axis Max</InputLabel>
          <Select
            value={yAxisMax}
            label="Y-Axis Max"
            onChange={(e) => setYAxisMax(e.target.value)}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={150}>150</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ flex: 1, maxheight: "60vh", mb: 2 }}>
        <ScatterChart
          series={[
            {
              id: "facility",
              data: filteredData.map((facility) => ({
                x: facility.x,
                y: facility.y,
                id: facility.id,
                facilityName: facility.facilityName,
              })),
              valueFormatter: (item) => {
                return `${item.facilityName} | Audit Score: ${item.x} | Spend: ${item.y}M`;
              },
              highlightScope: {
                highlighted: "item",
                faded: "global",
              },
              color: "#3538CD", // Changed the color of the dot to 3538CD
            },
          ]}
          grid={{ vertical: true, horizontal: true }}
          xAxis={[
            {
              label: "Audit Score",
              labelStyle: { fontSize: 12 },
              min: 0,
              max: 100,
            },
          ]}
          yAxis={[
            {
              label: "Spend (Million)",
              labelStyle: { fontSize: 12 },
              min: 0,
              max: yAxisMax,
            },
          ]}
          sx={{
            ".MuiChartsAxis-left .MuiChartsAxis-line": {
              stroke: "rgba(0, 0, 0, 0.2)",
            },
            ".MuiChartsAxis-left .MuiChartsAxis-tick": {
              stroke: "rgba(0, 0, 0, 0.2)",
            },
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          onItemClick={(event, itemData) => handleClick(event, itemData)}
        />
      </Box>
    </Box>
  );
};

export default RiskScatterPlot;
