import React, { useCallback, useState, useEffect } from "react";

import {
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Chip,
  Box,
  TextField,
  Paper,
  Tooltip,
  Button,
} from "@mui/material";
import { Select, MenuItem, FormControl } from "@mui/material";
import CustomChipLabel from "./customChipLabel.component";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  selectAuditCAPStatus,
  selectAuditIssueDetails,
  selectSelectedAuditIssueId,
} from "../../selectors/audit.selector";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { getValidData } from "../../utils/lib";
import { debounce } from "lodash";
import {
  selectAutoSaveTimer,
  selectSaveStatus,
} from "../../selectors/misc.selector";
import { saveStatus, setSaveStatus } from "../../reducers/misc.reducer";
import { useParams } from "react-router-dom";
import { updateAuditData, updateIssueData } from "../../reducers/audit.reducer";
import { CheckCircleOutline } from "@mui/icons-material";
import { AuditStyles, ComponentStyles } from "../../styles";

import { selectAuditData } from "../../selectors/audit.selector";
import CapApproved from "./capApproved.component";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AuditCapDetailsStatusOpen from "./auditCapDetailsStatusOpen.component";
import AuditCapDetailsStatusUploadedProof from "./auditCapDetailsStatusUploadedProof.component";
import DisplayUploadedProof from "./CAP/displayUploadedProof.component";

const RecommendationsList = ({ title, items, site }) => (
  <Paper
    elevation={0}
    sx={{
      mb: 2,
      backgroundColor: site ? "#F9FAFB" : "#FFF",
      padding: site ? "4px 8px" : "0px",
      borderRadius: site ? "12px" : "0px",
    }}
  >
    <Typography
      sx={{
        color: "#475467",
        fontSize: "16px",
        fontWeight: 700,
      }}
    >
      {title}
    </Typography>
    <List dense>
      {items.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemText
            primary={
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {item}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  </Paper>
);
const ParseDate = ({ title, items, site }) => (
  <Paper
    elevation={0}
    sx={{
      mb: 2,
      backgroundColor: site ? "#F9FAFB" : "#FFF",
      padding: site ? "4px 8px" : "0px",
      borderRadius: site ? "12px" : "0px",
    }}
  >
    <List dense>
      {[1].map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemText
            primary={
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {items}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  </Paper>
);

const AiGeneratedField = ({ label, children }) => (
  <Box>
    <Typography
      sx={{
        color: "#475467",
        fontSize: "16px",
        fontWeight: 700,
        mb: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      {label}
      <Tooltip title="Generated by AI">
        <AutoAwesomeIcon sx={{ fontSize: 16, ml: 0.5, color: "darkgrey" }} />
      </Tooltip>
    </Typography>
    {children}
  </Box>
);
const RootCauseAnalysis = ({ items }) => (
  <Paper elevation={0} sx={{ mb: 2 }}>
    <List dense sx={{ p: 0 }}>
      {items.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemText
            primary={
              <Typography
                sx={{ color: "#000", fontSize: "16px", fontWeight: 400, pl: 1 }}
              >
                {item}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  </Paper>
);
const AuditReport = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const data = useSelector(selectAuditIssueDetails);
  const saveStatus = useSelector(selectSaveStatus);

  const selectedAuditData = useSelector(selectAuditData);
  const auditId = params.auditId;
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [targetDate, setTargetDate] = useState(
    data?.targetDeadline ? new Date(data.targetDeadline) : null
  );

  const handleDateChange = (newDate) => {
    setTargetDate(newDate);
    // You may want to dispatch an action here to update the data in your store
    // dispatch(updateTargetDeadline(newDate));
  };
  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];
  // TODO: Replace with actual selector
  // Assuming it's 0-indexed
  // const [capStage, setCapStage] = useState(0);
  var capStage = 1;
  const mappingOfCAPStatusToStep = {
    IN_PROGRESS: 1,
    BRAND_REVIEWING_PLAN: 2,
    SITE_ADDED_ACTION_PLAN: 3,
    BRAND_APPROVED_PLAN: 4,
    SITE_ADDING_PROOF: 5,
    COMPLETED: 6,
  };
  const auditCAPStatus = useSelector(selectAuditCAPStatus);
  const currentStep = mappingOfCAPStatusToStep[auditCAPStatus];

  const selectedIssueId = useSelector(selectSelectedAuditIssueId);

  const debouncedSaveNote = useCallback(
    debounce((newNote, issueId) => {
      dispatch(
        updateAuditData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { capDetailsNote: newNote },
          issueId,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (saveStatus === "saved") {
      setTimeout(() => dispatch(setSaveStatus("")), 1000);
    }
  }, [saveStatus]);
  const [note, setNote] = useState("");
  useEffect(() => {
    setNote("");
    setNote(getValidData(data.note));
  }, [selectedIssueId]);
  const handleNoteChange = (event) => {
    const newValue = event.target.value;
    dispatch(setSaveStatus("saving"));
    setNote(newValue);
    debouncedSaveNote(newValue, selectedIssueId);
  };

  const handleApprove = (issueId, status = 4) => {
    dispatch(
      updateIssueData({
        auditId: params?.auditId,
        changes: { status },
        issueId,
      })
    );
  };
  const [selectedDate, setSelectedDate] = useState(null);
  const handleSetStatusToCI = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ p: 2 }}>
        {/* Header Section */}
        <Typography
          sx={{
            color: "#475467",
            fontSize: "30px",
            fontWeight: 500,
            textDecorationLine: "underline",
          }}
          gutterBottom
        >
          Finding
        </Typography>
        <Typography
          sx={{
            ...AuditStyles.auditReportFieldValue,
            fontSize: "24px",
            fontWeight: 400,
            mt: 2,
            mb: 3,
          }}
        >
          {data?.issueSummary || "-"}
        </Typography>

        {/* Details Section */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={3}>
            <Typography sx={AuditStyles.fieldTitle}>Finding ID</Typography>
            <Typography sx={AuditStyles.auditReportFieldValue}>
              {data?.clientCodeTitle || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={AuditStyles.fieldTitle}>Category</Typography>
            <Typography sx={AuditStyles.auditReportFieldValue}>
              {data?.type || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={AuditStyles.fieldTitle}>Subcategory</Typography>
            <Typography sx={AuditStyles.auditReportFieldValue}>
              {data?.subType || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={AuditStyles.fieldTitle}>Repeat Finding</Typography>

            <Chip
              label={data?.repeatedIssue || "Coming soon"}
              sx={{
                fontSize: "14px",
                color: "#344054",
                fontWeight: 500,
                textAlign: "center",
                borderRadius: "6px",
                border: "1px solid #D0D5DD",
                background: "#FFF",
                padding: "4px 10px",
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ my: 2 }}>
            <Typography
              sx={{
                ...AuditStyles.fieldTitle,
                mb: 1,
              }}
            >
              Finding Details
            </Typography>
            <Typography sx={AuditStyles.auditReportFieldValue}>
              {data?.issueDetail || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography
              sx={{
                ...AuditStyles.fieldTitle,
                mb: 1,
              }}
            >
              Audit Code or Legal Reference
            </Typography>
            <Typography sx={AuditStyles.auditReportFieldValue}>
              {data?.auditBasis || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography
              sx={{
                ...AuditStyles.fieldTitle,
                mb: 1,
              }}
            >
              Match From Master Finding List
            </Typography>
            <Typography sx={AuditStyles.auditReportFieldValue}>
              {data?.clientCode || "-"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={6} sm={6} md={3}>
            <AiGeneratedField label="Severity">
              <Typography variant="body1" sx={{ color: "error.main" }}>
                <CustomChipLabel severity={data.severity} />
              </Typography>
            </AiGeneratedField>
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <AiGeneratedField label="Remediation Timeline">
              <Typography
                sx={{
                  ...AuditStyles.auditReportFieldValue,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data?.timeline || "-"}
              </Typography>
            </AiGeneratedField>
          </Grid>
          {/* Rating Explanation */}
          <Grid item xs={12} sm={12} md={6}>
            <AiGeneratedField label="AI Rating Explanation">
              <Typography
                sx={{
                  color: "#45464E",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                {data?.ratingExplanation || "N/A"}
              </Typography>
            </AiGeneratedField>
          </Grid>

          {/* Internal Note */}
          <Grid item xs={12}>
            <Typography
              sx={{
                ...AuditStyles.fieldTitle,
                mb: 1,
              }}
            >
              Internal Note
            </Typography>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              value={note}
              onChange={handleNoteChange}
              helperText={
                saveStatus === "saving" ? (
                  <Typography
                    sx={{
                      color: "error.main",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Saving...
                  </Typography>
                ) : saveStatus === "saved" ? (
                  <Box sx={ComponentStyles.alignBoxItemsCenter}>
                    <Typography
                      sx={{
                        color: "success.main",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Saved
                    </Typography>
                    <CheckCircleOutline
                      color="success"
                      sx={{ ml: 1 }}
                      fontSize="small"
                    />
                  </Box>
                ) : (
                  ""
                )
              }
              FormHelperTextProps={{
                sx: {
                  ml: 0, // Adjust the left margin as needed
                },
              }}
              inputProps={{
                style: {
                  WebkitTextFillColor: "#45464E",
                  color: "#45464E",
                },
              }}
            />
          </Grid>
        </Grid>

        {/* CAP Recommendation Section */}

        {/* {(currentStep === 0 ||
          currentStep === 1 ||
          currentStep === undefined) && (
          <Grid container spacing={2}>
            <Grid item xs={currentStep === 1 ? 12 : 6} sx={{ pr: 4 }}>
              <Typography
                sx={{
                  color: "#475467",
                  fontSize: "30px",
                  fontWeight: 500,
                  textDecorationLine: "underline",
                  my: 4, // Added margin bottom for spacing
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  hyphens: "auto",
                }}
              >
                CAP Recommendations
              </Typography>
              <AiGeneratedField label="Root Cause">
                <RecommendationsList
                  title=""
                  items={
                    data?.rootCause ? String(data.rootCause).split("\n") : []
                  }
                />
              </AiGeneratedField>
              <AiGeneratedField label="Immediate Corrective Action Plan">
                <RecommendationsList
                  title=""
                  items={
                    data?.capRecommendations?.immediateCAP
                      ? String(data.capRecommendations.immediateCAP).split("\n")
                      : []
                  }
                />
              </AiGeneratedField>
              <AiGeneratedField label="Long Term Preventative Action">
                <RecommendationsList
                  title=""
                  items={
                    data?.capRecommendations?.longTermPreventativeAction
                      ? String(
                          data.capRecommendations.longTermPreventativeAction
                        ).split("\n")
                      : []
                  }
                />
              </AiGeneratedField>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  color: "#475467",
                  fontSize: "30px",
                  fontWeight: 500,
                  textDecorationLine: "underline",
                  my: 4, // Added margin bottom for spacing
                }}
              >
                Site Input
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: "#475467",
                      fontSize: "16px",
                      fontWeight: 700,
                      mb: 1,
                    }}
                  >
                    Accountable Person
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      fontStyle: "italic",
                      color: "rgba(0, 0, 0, 0.6)",
                    }}
                  >
                    {data?.responsiblePerson || "To be filled by site"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      ...AuditStyles.fieldTitle,
                      mb: 1,
                    }}
                  >
                    Target Completion Date
                  </Typography>
                  <Typography sx={AuditStyles.auditReportFieldValue}>
                    {data?.targetDeadline || "-"}
                  </Typography>
                </Grid>
              </Grid>

              <Typography
                sx={{
                  ...AuditStyles.fieldTitle,
                  mb: 1,
                  mt: 2,
                }}
              >
                Root Cause Response
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  fontStyle: "italic",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                {data?.rootCauseResponse || "To be filled by site"}
              </Typography>

              <Typography
                sx={{
                  ...AuditStyles.fieldTitle,
                  mb: 1,
                  mt: 2,
                }}
              >
                Immediate Corrective Action Plan
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  fontStyle: "italic",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                {data?.immediateCorrectiveActionPlanFacilityInput ||
                  "To be filled by site"}
              </Typography>

              <Typography
                sx={{
                  ...AuditStyles.fieldTitle,
                  mb: 1,
                  mt: 2,
                }}
              >
                Long Term Preventative Action Plan
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  fontStyle: "italic",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                {data?.longTermPreventativeActionPlanFacilityInput ||
                  "To be filled by site"}
              </Typography>

              <Typography
                sx={{
                  color: "#475467",
                  fontSize: "30px",
                  fontWeight: 500,
                  textDecorationLine: "underline",
                  my: 4, // Added margin bottom for spacing
                }}
              >
                CAP Update
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography
                    sx={{
                      ...AuditStyles.fieldTitle,
                      mb: 1,
                    }}
                  >
                    Status
                  </Typography>
                  <Box sx={ComponentStyles.alignBoxItemsStart}>
                    <CustomChipLabel
                      cap={data.statusChipLabel}
                      capLabel={data.status}
                    />
                    <Box sx={{ minWidth: "8px" }}></Box>
                    {data.status &&
                      !data.status.includes("Closed") &&
                      data.timeline && (
                        <CustomChipLabel
                          cap={"default"}
                          capLabel={data.timeline}
                        />
                      )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      ...AuditStyles.fieldTitle,
                      mb: 1,
                      mb: 1,
                    }}
                  >
                    Actual Completion Date
                  </Typography>
                  <Typography sx={AuditStyles.auditReportFieldValue}>
                    {data?.completionDate || "-"}
                  </Typography>
                </Grid>
              </Grid>

              <Typography
                sx={{
                  ...AuditStyles.fieldTitle,
                  mt: 3,
                }}
              >
                Verification Note
              </Typography>
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {data?.verificationNote || "-"}
              </Typography>
            </Grid>
          </Grid>
        )} */}

        {currentStep === 2 &&
        data?.status !== "BRAND_APPROVED_PROOF" &&
        data?.status !== "CLOSED" &&
        data?.status !== "CONTINUOUS_IMPROVEMENT" ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 3,
                    mb: 2,
                  }}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          color: "#475467",
                          fontSize: "20px",
                          fontWeight: 700,
                          textDecorationLine: "underline",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          hyphens: "auto",
                        }}
                      >
                        CAP Recommendations
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            color: "#475467",
                            fontSize: "20px",
                            fontWeight: 700,
                            textDecorationLine: "underline",
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                            hyphens: "auto",
                            mr: 2,
                          }}
                        >
                          Site Input
                        </Typography>

                        {data?.status === "BRAND_REVIEWING_PLAN" ? (
                          <>
                            <Button
                              variant="contained"
                              sx={{
                                textTransform: "none",
                                backgroundColor: "#2D3282",
                                borderRadius: "8px",
                                border: "1px solid #2D3282",
                                padding: "4px 16px",
                                fontSize: "15px",
                                fontWeight: "600",
                              }}
                              onClick={() => {
                                handleApprove(selectedIssueId, 4);
                              }}
                            >
                              Approve
                            </Button>
                            {data?.actionPlan?.capClosedClaimedBySupplier && (
                              <>
                                <Button
                                  variant="contained"
                                  sx={{
                                    textTransform: "none",
                                    backgroundColor: "#2D3282",
                                    borderRadius: "8px",
                                    border: "1px solid #2D3282",
                                    padding: "4px 16px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    ml: 1,
                                  }}
                                  onClick={() => {
                                    handleApprove(selectedIssueId, 11);
                                  }}
                                >
                                  Close CAP
                                </Button>
                                <Button
                                  variant="contained"
                                  sx={{
                                    textTransform: "none",
                                    backgroundColor: "#2D3282",
                                    borderRadius: "8px",
                                    border: "1px solid #2D3282",
                                    padding: "4px 16px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    ml: 1,
                                  }}
                                  onClick={() => {
                                    handleApprove(selectedIssueId, 12);
                                  }}
                                >
                                  Set Status to CI
                                </Button>
                              </>
                            )}
                          </>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              color: "#4CAF50",
                            }}
                          >
                            <CheckCircleIcon sx={{ mr: 1 }} />
                            <Typography sx={{ fontWeight: 600 }}>
                              {data?.status === "CONTINUOUS_IMPROVEMENT"
                                ? "Continuous Improvement"
                                : "Approved"}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {data?.status === "BRAND_REVIEWING_PLAN" &&
                data?.actionPlan?.capClosedClaimedBySupplier && (
                  <>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 700,
                          mb: 1,
                        }}
                      >
                        Uploaded Files
                      </Typography>
                      <DisplayUploadedProof
                        uploadedProof={data?.proofDocuments}
                        displayTitle={false}
                      />
                    </Grid>
                  </>
                )}
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                {/* <Grid container spacing={2}> */}
                {/* <Grid item xs={6}> */}
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Accountable Person
                </Typography>

                <RecommendationsList
                  title=""
                  items={
                    data?.actionPlan?.responsiblePerson
                      ? String(data.actionPlan.responsiblePerson).split("\n")
                      : []
                  }
                  site={true}
                />
                {/* </Grid> */}
                {/* <Grid item xs={6}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        mb: 1,
                      }}
                    >
                      Target Completion Date
                    </Typography>

                    <ParseDate
                      title=""
                      items={
                        data?.actionPlan?.deadline
                          ? new Date(data.actionPlan.deadline).toDateString()
                          : ""
                      }
                      site={true}
                    />
                  </Grid> */}
                {/* </Grid> */}
              </Grid>
              <Grid item xs={6}></Grid>

              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  Root Cause
                </Typography>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <RecommendationsList
                  title=""
                  items={
                    data?.actionPlan?.rootCause
                      ? String(data.actionPlan.rootCause).split("\n")
                      : []
                  }
                  site={true}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  Root Cause Response
                </Typography>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <RecommendationsList
                  title=""
                  items={
                    data?.actionPlan?.rootCauseAnalysis
                      ? String(data.actionPlan.rootCauseAnalysis).split("\n")
                      : []
                  }
                  site={true}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography fontSize="16px" fontWeight={700}>
                  Immediate Corrective Action Plan
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <RecommendationsList
                  title=""
                  items={
                    data?.capRecommendations?.immediateCAP
                      ? String(data.capRecommendations.immediateCAP).split("\n")
                      : []
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#FFFFE0",
                    px: 1,
                    py: 0.5,
                    borderRadius: "12px",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    Target Completion Date:{" "}
                  </span>
                  {data?.actionPlan?.deadlineForImmediateActionPlan
                    ? String(
                        moment(
                          data.actionPlan.deadlineForImmediateActionPlan
                        ).format("MMM. DD, YYYY")
                      ).split("\n")
                    : []}
                </Typography>
                <RecommendationsList
                  title=""
                  items={
                    data?.actionPlan?.immediateCorrectiveAction
                      ? String(data.actionPlan.immediateCorrectiveAction).split(
                          "\n"
                        )
                      : []
                  }
                  site={true}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography fontSize="16px" fontWeight={700}>
                  Long Term Preventative Action
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <RecommendationsList
                  title=""
                  items={
                    data?.capRecommendations?.longTermPreventativeAction
                      ? String(
                          data.capRecommendations.longTermPreventativeAction
                        ).split("\n")
                      : []
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    backgroundColor: "#FFFFE0",
                    px: 1,
                    py: 0.5,
                    borderRadius: "12px",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    Target Completion Date:{" "}
                  </span>
                  {data?.actionPlan?.deadlineForLongTermPreventativeAction
                    ? String(
                        moment(
                          data.actionPlan.deadlineForLongTermPreventativeAction
                        ).format("MMM. DD, YYYY")
                      ).split("\n")
                    : []}
                </Typography>

                <RecommendationsList
                  title=""
                  items={
                    data?.actionPlan?.longTermPreventativeAction
                      ? String(
                          data.actionPlan.longTermPreventativeAction
                        ).split("\n")
                      : []
                  }
                  site={true}
                />
              </Grid>
            </Grid>
          </>
        ) : (data?.status === "Open" ||
            data?.status === "Closed" ||
            data?.status === "CI" ||
            data?.status === "OPEN" ||
            data?.status === "IN_PROGRESS" ||
            data?.status === "SITE_ADDED_ACTION_PLAN" ||
            data?.status === "SITE_ADDING_ACTION_PLAN") &&
          data?.status !== "CLOSED" ? (
          <AuditCapDetailsStatusOpen data={data} />
        ) : (
          <></>
        )}
        {currentStep === 4 ||
        data?.status === "SITE_ADDED_PROOF" ||
        data?.status === "BRAND_APPROVED_PROOF" ||
        data?.status === "CLOSED" ||
        data?.status === "CONTINUOUS_IMPROVEMENT" ? (
          <>
            <AuditCapDetailsStatusUploadedProof data={data} />
            <CapApproved
              immediateActions={data?.actionPlan?.immediateCorrectiveAction}
              longTermActions={data?.actionPlan?.longTermPreventativeAction}
              status={data?.status}
              rootCauseAnalysis={data?.actionPlan?.rootCauseAnalysis}
              responsiblePerson={data?.actionPlan?.responsiblePerson}
              targetDate={data?.actionPlan?.deadline}
              rootCause={data?.actionPlan?.rootCause}
              deadlineForImmediateActionPlan={
                data?.actionPlan?.deadlineForImmediateActionPlan
              }
              deadlineForLongTermPreventativeAction={
                data?.actionPlan?.deadlineForLongTermPreventativeAction
              }
            />
          </>
        ) : (
          <></>
        )}

        {/* <Typography
          sx={{
            color: "#000",
            textAlign: "right",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
          }}
        >
          Last Updated by AuditSense AI
        </Typography> */}
      </Box>
    </Container>
  );
};

export default AuditReport;
